import { default as indexZoEqOCk2vpMeta } from "/home/saltytostitos/dev/error-logger/pages/index.vue?macro=true";
import { default as logssWAeqvHrD7Meta } from "/home/saltytostitos/dev/error-logger/pages/logs.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/home/saltytostitos/dev/error-logger/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "logs",
    path: "/logs",
    component: () => import("/home/saltytostitos/dev/error-logger/pages/logs.vue").then(m => m.default || m)
  }
]